export default {
    "assign-and-monitor": "000835de-e632-4945-ae0c-9f4d4abd28d1",
    "log-in-with": "004e72b5-69dd-415c-8abd-38db8f21510e",
    "data-protection-notice-for-students": "00c6de4c-251d-43f2-9594-2bcaa8fa40c9",
    "group-name": "03776dee-1e5b-46fe-a866-abbeba6c4561",
    "create-an-account-or-log-in-with-the-service-that-you-use-at-your-school-if-the-service-you-normally-use-is-not-among-the-options-please-ask-your-teacher-to-contact-edu-pila-oecd-org": "03fbfc3f-e823-43b8-90ee-e4bb8d3efa1b",
    "group-details": "041dc36b-dc14-464b-9ae5-738e5b6179d6",
    "add-students-to-your-student-list": "04507eba-8ac9-4f68-a51a-8a759ac1e59d",
    "request-role-of": "0490c80c-90a2-4cb7-b71a-7592a7b1b2ac",
    "select-content-type": "07a1a9a0-c0e5-4242-8cb5-a7f5e00ebb27",
    "intermediate": "0872198a-4efd-43bb-9584-3dd93e7f5544",
    "remove-all": "0da5cf62-2e2c-4780-aa33-2b6db2e226c7",
    "classes-assigned": "0e0d9ff6-a054-4dae-905e-f36f5813f6c3",
    "select-teachers-in-the-group": "0e8987df-634e-4c5d-827d-9dca0d250ebd",
    "admin-role-required": "0ead46f7-0382-4141-a2f2-43bc1b09aa22",
    "create-edit-class": "0eefed28-cda4-4a38-9459-8e442edf3ae6",
    "per-the-pila-personal-data-protection-notice-please-check-this-box-to-confirm-you-have-collected-parental-guarding-consent-from-your-students-as-required-by-your-local-or-national-laws": "0f8f32ff-f987-4566-bf1c-04ac9922d8cb",
    "new-group": "0fb62717-4151-40c2-9ed3-86f4241804e2",
    "matching": "104f2371-a1d2-49be-999c-ae6f0059f8dd",
    "live-monitoring-dashboard": "1109d648-360a-4aac-86f0-1737e1c569c9",
    "you-currently-have-no-students": "119d9eb8-34e0-45a3-9f9e-074dffb2f451",
    "role": "11ebccf3-6ad3-4af8-a8c3-1409b9f8c299",
    "give-your-group-a-name": "12168cc1-9312-4d66-b899-75808c22e8dc",
    "there-is-an-issue-with-your-assignment-please-ask-your-teacher-to-ensure-they-have-assigned-the-intended-content": "15179139-79bc-49e5-8cf2-2ab57432a64e",
    "undo-request": "15a49ec3-7d5a-40f0-ba7e-31e68f7bcdcb",
    "in-class": "16743e08-e34f-42bd-8bb7-241a6c512bdc",
    "it-looks-like-you-do-not-have-any-assignments-please-speak-to-your-teacher": "168f9d54-9f78-4a1d-ad43-01fd411b9507",
    "assignment-details": "169745be-e7be-40de-8194-9a7cca52f9d4",
    "by-creating-an-account-and-using-the-platform-for-innovative-learning-assessments-pila-you-are-agreeing-that-know-learning-can-collect-and-store-data-about-your-activity-and-google-analytics-and-google-cloud-platform-can-process-this-data": "19e34649-83e5-4bd2-ab60-521987c8e24e",
    "join-studies": "1cce499c-3b12-421f-9354-8448c7ce265a",
    "manage-data": "1d635049-8387-46e1-b2bf-69b251ee8e05",
    "teachers": "1d8c8f9c-545c-42e3-90d9-9a5cf7d193b3",
    "results": "1db8fc03-a62c-47bd-bb4c-016304074463",
    "thanks-to-the-pila-data-security-system-your-teacher-will-receive-your-data-in-digital-dashboards": "1f497544-164a-4d0e-9ad7-c14f13eff25b",
    "you-currently-have-no-active-groups": "202e81f1-61ef-4018-9c05-a91910a233e2",
    "download": "21191732-36f0-41a2-9521-240d2659de26",
    "other-classes": "245ae0a0-06b8-4505-8039-22962e6be37f",
    "you-currently-have-no-teachers": "26247716-5a48-41de-a7f1-691e1f60bd63",
    "leave": "27e4395a-4732-4f49-ae47-67f813e4ebfa",
    "new-class": "294a8311-f5ac-4f3d-b820-808ba8b0fbd9",
    "terms-and-conditions": "2a83110a-ff39-4199-8033-49a0ac4b99fd",
    "description": "2e1514cd-9651-4b76-8591-aa1851ddd36f",
    "available-languages": "301fd5c6-c754-4076-8f26-308699634abf",
    "teachers-in-group": "32204083-ca5c-4967-8212-b3635ff9fa0e",
    "please-send-your-full-name-school-institution-and-this-anonymous-id-to-edu-pila-oecd-org-in-order-to-obtain-access-to-pila": "32cd6659-6998-42e3-8c8a-7089d1f511cd",
    "link-students-to-you": "332b677b-51c0-4e0b-b6a1-a7993d90e0e3",
    "my-assignments": "33bcae36-13b9-4d83-9ee7-ce814f487687",
    "teacher-name": "3847b12b-cdd2-41c2-a6a0-ba864ee2b440",
    "join": "394e3174-39bb-4751-b66d-188dd1e81e33",
    "privacy-policy": "396f9e91-afce-46bb-8c62-e25e8eb9b950",
    "explore": "3a7f3fe6-f1c3-4306-b870-b3b1b5c7e3c5",
    "visit": "3c6fe9b4-b90d-4d2a-8297-5f47f475e1fa",
    "my-teacher-groups": "3ce29235-f8c8-43c4-b15d-599ce5c03586",
    "by-creating-my-teacher-account-i-agree-to-the-terms-of-service-and-the-processing-of-my-personal-data-as-described-in-the-data-protection-notice": "3d069249-391d-462b-b2a2-be5ea3392072",
    "select-the-content-to-assign": "3eda342d-dcd4-4812-a514-850d9745f56d",
    "create-modify-assignment": "3fc6c79b-0b0c-4306-a8a3-8dffae71a78e",
    "previewing": "42694527-581f-449a-a712-722dd0629acb",
    "generative-ai-module-dashboard": "42d2c47e-cf88-45b2-a210-e11ce44737d8",
    "assigned-by": "43dd0195-19d2-4499-81f5-b70948f653be",
    "user": "43fe3d4d-8267-4846-b271-7e8b807d365d",
    "role-requests": "44308b2c-092f-41ec-afb4-92e9b46b51d8",
    "admin": "472543ac-8131-4f53-a6e2-8602834fb638",
    "all-teachers": "48ac4359-ff84-48ca-9c0f-03e98f1b9213",
    "new-string-to-translate": "498087be-e325-4aba-a399-e553985b40a9",
    "game-design": "4b269083-4e31-4b5d-b388-f81c10e5a014",
    "cancel": "4d6868d9-d2f6-4e38-b812-1d908c85625b",
    "play": "4de98b26-66ee-4c25-aeed-12872b5a7706",
    "give-your-class-a-name": "4f67c1de-15ed-4007-8c4f-0377975ddae9",
    "terms-of-service-and-data-protection-notice": "501aa9ea-f8d1-4414-bcb7-1b07cd45e2b7",
    "share-this-link-with-your-students": "50a4f41d-9cb4-4c67-84a2-33ca9de010e3",
    "class-assigned": "50cbb411-dc87-468c-96b6-87ae792c2375",
    "click-here-to-reload-once-you-receive-approval-confirmation": "5111b437-00be-4c2e-b460-4693234c08be",
    "you-have-joined-this-teacher": "522d3bc8-2c52-4e11-925e-490d6becb990",
    "copy-link": "53730068-3277-4b80-b2c0-d2cf2c76ac2d",
    "students-in-class": "573b2241-36d9-42e4-b9f5-edd5ec59fc1d",
    "my-classes": "585170d7-9793-42b3-ac89-1648fa20b416",
    "you-currently-have-no-assignments-ask-your-teacher-if-you-expect-an-assignment-here": "5b8bfd7a-ad86-46d0-84b8-edeedb1fd30c",
    "done": "5d5c7a94-6add-489b-b5b3-5a20c33209b4",
    "creative-thinking": "60294f45-0811-4fd6-91ba-7a4c03bff561",
    "total-time-seconds": "60c2e732-ba81-4b58-af09-5c13e5359227",
    "teacher-groups": "60f2b7b8-5b06-466d-9d67-7a737ebbc4c9",
    "no-classes-assigned": "62467328-6959-40a6-bee2-de278e5e130e",
    "new-assignment": "625df420-cb5c-4c1b-9ad9-8d5751ad7723",
    "no-data-available": "64e62d31-964a-490b-9a8d-96e4d13486bd",
    "assignment": "655967af-c781-4529-8f7f-cd1f1d45054c",
    "join-teacher": "65faee03-a10b-44b9-ad5c-c1bedb2951df",
    "select-classes-for-the-assignment": "680338da-2454-453c-9eac-7a493c59ac4e",
    "self-regulated-learning": "680d932b-7e9e-43ed-b8c3-62b809cde595",
    "all-saved-versions-of-your-answers-solutions": "683058f1-8d07-4de5-91e4-b64b5250d830",
    "no-role-requests-yet": "6aaf75e2-87f4-4dc0-b61b-b75fb2c494bd",
    "to-request-deletion-of-data-please-email-data-requests-knowlearning-org": "6af68838-3904-4a2e-8a45-e05aca13d665",
    "upload": "6b415534-caee-4c32-b5d7-d40481afe93c",
    "pila-create": "6c7111db-e67e-4c83-a893-03577f56e558",
    "select": "6ce15629-cfd3-4d6c-a3ae-04b6a08f2a14",
    "create-your-own-content": "6f38bc4a-ef50-4f70-aad6-3b97dea4b4a9",
    "login-report": "70532ac9-6448-416c-a583-3278dea65b6e",
    "you-will-be-able-to-participate-in-research-studies-on-pila-soon-check-back-in-a-few-months": "7086209f-12a0-4368-8fdd-f0f6054d1a9e",
    "name": "718504ad-e26c-4063-85a6-03e089509141",
    "select-students-in-the-class": "71e337b2-0f49-4879-9945-a3800c736109",
    "unarchive": "73aecd24-0a6c-4742-8c22-fdd62474e13c",
    "content-code-or-url": "73f51174-a90e-4075-a795-be1add595a89",
    "loading": "7582e09d-b3c6-4f33-916b-c3d4af422bcd",
    "bettys-brain": "76bc489f-cb3a-4e76-a15d-2c5043e969b8",
    "select-new": "784c871c-5605-447a-a7fc-0469d65667d4",
    "customised-items": "79926b5b-fd18-4929-b6d8-eb80eb6f52e2",
    "secondary": "79d9d97c-4df9-4c34-acb0-75932e1756cc",
    "give-your-assignment-a-description": "79e8967f-e430-4223-b270-837aef5d4dde",
    "data-protection-notice-for-teachers": "7ac4bb39-6560-49ba-b9dc-ca1acde60c67",
    "num-groups-assigned": "7c23d38c-f057-4f8a-bf7c-cfb02668fe21",
    "give-your-assignment-a-name": "7cfa72a0-662c-444f-8dbc-f0581dec92e7",
    "save": "8025821b-e2ba-447b-b84d-a829210ad085",
    "log-out": "8066a76b-2a74-43dc-be11-0e6f48ef3e1c",
    "student-name": "80916db4-cf31-4d0d-b6c5-2214bc8c972f",
    "hide-filters": "80d45706-ed45-42e7-9d5b-11dc02d6dbd0",
    "include-your-pila-user-id": "81b2b672-94dc-462c-85c5-f92611289c40",
    "please-wait-for-admin-approval": "828cca4c-2d7b-4a96-93f1-132ff08998cd",
    "access-code": "84431d2f-35e4-44d4-8fad-444e70970af8",
    "assignments": "84fc18e2-df98-4e36-9ad8-6ddadc48ea2f",
    "contact-us": "858f1f12-6e29-4b05-b9b7-271a134284f8",
    "add-all": "85981d81-0854-4517-b5af-755a2fc11d97",
    "user-id": "860888b0-d982-4c19-b9c8-e4f7e1d4d0dd",
    "archive": "8624127a-5ec7-4118-a9a8-0a98f1d5ddcb",
    "content": "8659e8e6-d329-4f79-b09d-22dc9e213a90",
    "class-name": "87325f5f-117d-40ec-8312-dc47f2cd8a6e",
    "selected-trainer-for-role-request": "87de381f-e593-457a-b887-d341e22537fc",
    "assigner": "884a27aa-14d3-422c-86c7-2ad83f816381",
    "show-filters": "89bc84e3-373b-49b8-8ec4-f4c5a7eaa00a",
    "undo": "89f764c3-2e54-4acd-a3a2-d701c5924753",
    "create-account": "8a930a81-a1e6-4451-b11e-2f64767aa372",
    "repetition": "8badb88b-621e-47ad-841d-211a84f24bd7",
    "beginner": "8c311095-bc89-492a-9d75-b93ad8e8c9b3",
    "non-members": "8d05a5a4-7200-4bd2-b12b-5a31030be65a",
    "preview": "8e2697b7-e7dd-4011-83c4-6e0ba7b71ae2",
    "you-currently-have-no-active-classes": "8e9b7102-cfae-49ba-81a0-5dc3e6cd02e3",
    "request-role": "900bca65-e088-401d-a0b4-0846cdb7e99f",
    "files": "906a6b61-67cc-459a-a9c2-439a631b2b95",
    "know-learning-will-keep-this-data-safe-and-not-share-it-in-any-way-externally": "917ef00b-beb6-4b71-8ecf-b8d9e133cee2",
    "num-logins": "9323cb5f-5f25-4765-b8c9-9244cf125535",
    "if-you": "956d40e7-da21-44a6-a7c3-ff83069357bc",
    "close": "96c18794-0bc7-4db7-b0ff-91cb2280201e",
    "assignment-name": "96d5f5bd-39cb-43b4-b62a-b4f4ae74607b",
    "requested": "96e7e995-62d0-4d54-8fbe-d4709b6c54a0",
    "members": "9bc15f39-7952-4b4f-b41f-87258be722c6",
    "trainer": "9c6e8082-c38e-41ae-b15a-adb477e65ccf",
    "add-content": "9ca6d784-4a17-4c94-ba63-3d37f2d8e1d8",
    "invalid-access-code": "9cb54683-edcc-4fda-92f2-88b06bb32d13",
    "add": "9cd8f118-6f0a-418b-b69a-bb7227951889",
    "rearrange-items": "9d3a53dc-983e-4843-bc92-d65c6730ea36",
    "other-groups": "9e541dd7-f02e-4c76-8eea-a8364daf9cc0",
    "your-teachers": "a1485f11-c8e3-44b8-bce9-10a6f21bd4aa",
    "karel-the-turtle": "a569d925-90ae-4d49-a3c3-f001007231b6",
    "the-kinds-of-activity-data-include": "a5cbbdaf-37c9-468a-bd26-7d2b62b92c6a",
    "show-archived": "a930d21d-0119-49f9-bc98-46755bf91f4f",
    "competency-dashboard": "ac7de5e9-6c60-400e-8461-2bdbe141f5c1",
    "pila-studies": "adeff292-2055-4237-a0b9-e6be4e28258f",
    "go-to-assignments": "aef1484a-66db-4a23-a297-685997c16a96",
    "select-your-trainer": "b12fc029-5727-43b2-94a4-b5fed6884d25",
    "assigner-id": "b2a452ee-8828-4b75-b94d-f40546f68922",
    "archived": "b38524cc-616f-4a83-8c99-b9b1438ad916",
    "no-results-found-for-your-filter": "b54bf918-32f0-4afc-98c1-7c0e2834a00c",
    "teacher": "b5a2885e-c9e0-4077-8da6-5cb09af92739",
    "owner": "b7159c28-c9c0-476f-a80a-4ba0a6a6a770",
    "success": "b71871dc-0c98-480d-afdd-a18ca990527d",
    "computational-problem-solving": "b7e440eb-983f-49fa-abc7-4d66a54e899f",
    "for-support-please-email-your-trainer": "b94ec24a-1ac4-486f-8552-0581275472c5",
    "sequence": "b992529f-2dcb-480e-804c-5496ff39a81e",
    "updated": "ba1310cf-f17e-40f4-93b2-b0a610c0d5ac",
    "add-content-by-id-or-url": "bb11f58d-f083-44a5-b332-60dcfd5445b4",
    "to-know-learning-collecting-and-storing-this-data-please-speak-with-your-teacher-and-do-not-create-a-student-account-on-pila": "bf7af54f-2b69-4e5b-8b48-03d1bc5dd380",
    "created": "c3efe218-78af-45c3-8b95-d615d70e895a",
    "all-students": "c4a9216e-7f8c-4c7c-bab4-1dafc214f098",
    "modify": "c505752e-eabe-430e-81ef-1fb30a0331f6",
    "dont-have-an-access-code-contact-edu-pila-oecd-org-to-express-interest-in-using-pila-in-your-classroom-or-for-your-research": "c55b617c-5d4a-485d-8fa5-a23fef1816c5",
    "items-per-page": "c5d11808-3ad9-4ec8-b1e6-9f9d1c3f5477",
    "challenge": "c5d68bd2-9303-4cba-aabf-b4d5f07cc68f",
    "enter": "c78f7471-dc2b-4fbb-bf42-1a75eb01d695",
    "class-details": "cafd11e5-6ae1-4da6-bcc3-249e387ffb8b",
    "in-group": "cba9d02e-280e-40c0-b0d1-62f2a718a88a",
    "anonymous": "cc46c274-91c6-4628-aa79-894e5459ca75",
    "my-teachers": "cd008511-7115-4e7f-b76f-2ce05a1df13e",
    "systems-thinking": "cd05fbe7-7edc-4d8b-911f-2ce5c779139c",
    "you-have-requested-the-role-of": "ce6dd173-369a-4dbb-a824-40541474c85a",
    "link-copied": "cef84cd5-9646-4989-b1d0-ce8c5a407c3f",
    "my-students": "d08bf035-89ac-4951-ab10-fd5fdc34d461",
    "create-new-content": "d099e0f5-2ea4-4088-bef4-4394133779b2",
    "create-sequences-and-custom-question-types": "d505e809-0800-4cf0-a5e7-788895ba88fc",
    "are-you-sure": "d837ad79-46dd-4fa7-b3fe-b4538a2bbba9",
    "primary": "d99fc54a-35b8-489d-8d93-a3b8a14f1a58",
    "add-new": "d9c52655-be3e-4069-80fc-bb282b1c0125",
    "create-edit-group": "dbe26871-de3b-476c-8329-3800dd471c1d",
    "do-not-agree": "dddddf89-1caf-4660-ae49-011439fd4d0e",
    "your-clicks": "de730131-682b-4c45-9bf2-6f12607d3468",
    "you-will-be-able-to-access-your-assignments-and-results-in-your-student-account-you-can-read-more-about-how-pila-protects-your-data-in-the-data-protection-notice-for-students": "deb0fef5-cdd3-4978-b4f9-f81f87547325",
    "reports": "e0caf47b-d5b6-469d-b01d-680d46e66ce9",
    "studies": "e0d96928-e89e-44f0-90ac-a47b6f912406",
    "you-will-be-able-to-participate-in-pila-studies-soon-check-back-in-a-few-months": "e13cfb2f-6ba7-4dbd-9710-9d7c3134398a",
    "create-karel-block-based-programming-tasks": "e23f14eb-e41b-4e58-a6e0-0619b12ad401",
    "import-content": "e516ff12-2574-47f7-87e3-c0a417e48a95",
    "student": "e51c9583-c1ff-48ba-8a88-68eac01f089f",
    "select-from-pairs": "e582bb90-3223-4997-a782-c25270bca56f",
    "assignment-results": "e58bbff6-bc47-4195-b45b-0aadec5b8606",
    "enter-an-encryption-key-word-you-will-remember-this-key-word-will-be-used-to-allow-you-to-see-your-students-names-while-preserving-the-anonymity-of-their-data-for-all-other-users": "e796b310-f99f-4c15-a3e1-34725225a05a",
    "causal-reasoning": "e7d7efca-b65f-4d8a-86ca-f2532a4d3695",
    "your-teachers-are-the-only-ones-who-will-be-able-to-identify-who-you-are": "e9a20903-04a2-4614-a3cd-965960796418",
    "invalid-id-or-url": "e9a4cca2-dcb0-4ecf-a25f-8d814dc69acf",
    "files-alt": "ebed5ed0-af1d-4888-a5a3-0303f0b45889",
    "joining-teacher": "ec74d7d7-3fcc-47cc-b281-7b0985b323d1",
    "agree": "ec9ba7b3-807d-4c2c-a3ba-7f0e55f1b268",
    "enter-encryption-key-word": "ed53a571-b06e-46eb-a561-9db34c6b91a7",
    "remove": "edb42907-4df6-4e7a-afa2-3a2249a11449",
    "expert-content": "ee00aef5-c413-4a48-a907-6f30f297b158",
    "information-about-your-screen-and-device": "ee7123b7-4908-4313-8612-31bb3652928a",
    "researcher": "ef20dff4-17f0-4359-87e9-42e7bd718c48",
    "create": "efc18a8e-badd-45d6-9397-d122d8d28251",
    "trainers": "f04aaf15-9389-4396-b63c-536a05bfde11",
    "grant": "f27bd8be-ab65-494c-9ade-01180cde829c",
    "terms-of-service-for-teachers": "f4580667-d624-42f8-83a4-db80e744145f",
    "last-login": "f6a24ec2-02cf-48b6-9d48-e197287a29b4",
    "to-create-a-teacher-or-researcher-account-please-enter-your-access-code-below": "f7364c65-874d-42ef-9dd9-6c3d859fb099",
    "all": "f73a86ca-5e5e-4437-a024-96f7538b2872",
    "leaving-teacher": "f7a29927-53a1-4a2b-acff-feab1fdaeb60",
    "your-data": "f8fadeca-2eee-4da2-ae9b-5faf75d85119",
    "item-library": "fa96171b-eb83-4c7b-a1d1-b4635daa413c",
    "total": "fac631d0-12f2-4430-96b7-20ec1138d6e5",
    "created-by": "fc15495e-fc19-4f7e-9d91-1cfea8d56bf2",
    "your-content": "fd643305-d167-4c97-8256-39a7e5dfc614",
    "dashboard": "fe4c0822-02da-4ed2-8629-e1aa4db8647d",
    "for-support-please-email-edu-pila-oecd-org": "6e3a0bba-2ac5-4ed3-aefd-336d7de670e8",
}